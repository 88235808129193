.app-bg {
  background-image: url("../src/assets/images/gag-login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.MuiFormControl-root .MuiInputBase-multiline {
  border-radius: 0;
}
.MuiLoadingButton-loading .MuiLoadingButton-loadingIndicator{
  color: #fff !important;
}

.gDisabledButton {
  color: #fff !important;
  border: 1px solid rgba(255, 255, 255, 1) !important;
  opacity: 0.75 !important;
}
.MuiLoadingButton-loading.gDisabledButton {
  color: transparent !important;
}

.login-logo {
  width: 250px;
  margin: 0 auto;
}

.avatar-box {
  height: 150px;
}

@media all and (max-width: 475px) {
  .avatar-box {
    height: 85px;
  }
}
